<template>
  <a-input :value="name" :size="size" placeholder="请选择资产" readonly @click="open" :disabled="disabled">
    <template #suffix>
      <i class="fa fa-sitemap gray"/>
    </template>
  </a-input>
  <cc-dialog-table ref="dialog" title="资产"
                   v-model:code="assetCode"
                   v-model:name="assetName"
                   :columns="assetColumns"
                   :search-bar-span="8"
                   :multiple="multiple" :size="size"
                   url="/ams/api/ams/asset-info-detail/query-choose-list"
                   :args="{...args}"
                   @loaded="loaded" @selected="selected"/>
</template>

<script lang="ts">
  import { defineComponent } from 'vue'
  import assetStatus from "../apis/ams/asset-status.json";

  export default defineComponent({
    name: 'z-input-asset',
    emits: ['selected', 'update:code', 'update:name', /* rules自动生成事件 */ 'blur', 'change'],
    props: {
      id: String,
      multiple: {
        type: Boolean,
        default: false
      },
      size: {
        type: String,
        default: 'small'
      },
      code: {
        type: String
      },
      name: {
        type: String
      },
      args: {
        type: Object,
        default: {}
      },
      disabled: {
        type: Boolean,
        default: false
      }
    },
    data() {
      return {
        assetCode: null,
        assetName: null,
        assetColumns: [
          {
            title: '使用部门', dataIndex: 'useOrgName', key: 'useOrgName', type: 'string', control: 'dept', width: 150, query: true, options: {
              valueField: 'useOrgId',
              nameField: 'useOrgName'
            }
          },
          { 'title': '资产编号', 'dataIndex': 'assetCode', 'key': 'assetCode', 'type': 'string', 'control': 'text', 'width': 150, 'query': true },
          { 'title': '资产名称', 'dataIndex': 'assetName', 'key': 'assetName', 'type': 'string', 'control': 'text', 'width': 150, 'query': true },
          { title: 'SN', dataIndex: 'sn', key: 'sn', type: 'string', control: 'text', width: 150, query: true},
          { 'title': '规格型号', 'dataIndex': 'model', 'key': 'model', 'type': 'string', 'control': 'text', 'width': 150, 'query': false },
          { 'title': '资产类别', 'dataIndex': 'categoryName', 'key': 'categoryName', 'type': 'string', 'control': 'text', 'width': 150, 'query': false },
          {title: '单价', dataIndex: 'price', key: 'currentPrice', type: 'bigdecimal', control: 'float', width: 150, query: true},
          { 'title': '状态', 'dataIndex': 'status', 'key': 'status', 'type': 'integer', 'control': 'number', 'width': 150, 'query': false, customRender: (data: any) => {
              const item = assetStatus.find(d => d.value === data.value)
              return item ? item.label : ''
            }
          }
        ]
      }
    },
    methods: {
      open() {
        (this.$refs.dialog as HTMLFormElement).open()
      },
      loaded(data: any[]) {
        // 设置默认值
        if (this.code) {
          if (this.multiple) {
            // 多选模式
            // this.selectedRowKeys = this.code.split(',')
          } else {
            // 单选模式
            // this.selectedRowKeys = [this.code]
          }
        }
      },
      reset() {
        (this.$refs.dialog as HTMLFormElement).reset()
        this.assetName = null
        this.assetCode = null
      },
      selected(data: any) {
        if (this.multiple) {
          this.$emit('update:code', data.map((d: any) => d['assetCode']).join(','))
          this.$emit('update:name', data.map((d: any) => d['assetName']).join(','))
        } else {
          this.$emit('update:code', '' + data['assetCode'])
          this.$emit('update:name', data['assetName'])
        }
        this.$emit('selected', data)
      }
    }
  })
</script>

<style scoped>

</style>
