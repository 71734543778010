import { createRouter, createWebHashHistory, createWebHistory } from 'vue-router'

const routes = [
  {
    path: '/',
    name: 'board',
    component: () => import('../views/board.vue')
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('../views/login.vue')
  },
  {
    path: '/asset-category',
    name: 'asset-category',
    component: () => import('../views/ams/asset-category-list.vue')
  },
  {
    path: '/asset-add',
    name: 'asset-add',
    component: () => import('../views/ams/asset-info-add.vue')
  },
  {
    path: '/asset-info',
    name: 'asset-info',
    component: () => import('../views/ams/asset-info-list.vue')
  },
  {
    path: '/asset-admin',
    name: 'asset-admin',
    component: () => import('../views/ams/asset-info-list-admin.vue')
  },
  {
    path: '/borrow-record',
    name: 'borrow-record',
    component: () => import('../views/ams/borrow-record-list.vue')
  },
  {
    path: '/damage-or-lose',
    name: 'damage-or-lose',
    component: () => import('../views/ams/damage-or-lose-list.vue')
  },
  {
    path: '/depreciation',
    name: 'depreciation',
    component: () => import('../views/ams/depreciation-list.vue')
  },
  {
    path: '/maintain-record',
    name: 'maintain-record',
    component: () => import('../views/ams/maintain-record-list.vue')
  },
  {
    path: '/move',
    name: 'move',
    component: () => import('../views/ams/move-list.vue')
  },
  {
    path: '/scrap',
    name: 'scrap',
    component: () => import('../views/ams/scrap-list.vue')
  },
  {
    path: '/check-plan-list',
    name: 'check-plan-list',
    component: () => import('../views/ams/check-plan-list.vue')
  },
  {
    path: '/supplier',
    name: 'supplier',
    component: () => import('../views/ams/supplier-list.vue')
  },
  // 附加业务
  {
    path: '/asset-query-list',
    name: 'asset-query-list',
    component: () => import('../views/ams/asset-query-list.vue')
  },
  {
    path: '/operation-log-list',
    name: 'operation-log-list',
    component: () => import('../views/sys/operation-log-list.vue')
  },
  {
    path: '/dict-list',
    name: 'dict-list',
    component: () => import('../views/bas/dict-list.vue')
  },
  {
    path: '/dict-sys-list',
    name: 'dict-sys-list',
    component: () => import('../views/bas/dict-sys-list.vue')
  },
  {
    path: '/attachment-list',
    name: 'attachment-list',
    component: () => import('../views/bas/attachment-list.vue')
  },
  {
    path: '/asset-all',
    name: 'asset-all',
    component: () => import('../views/ams/asset-all-list.vue')
  },
  {
    path: '/ams-log',
    name: 'ams-log',
    component: () => import('../views/ams/ams-log-list.vue')
  },
  {
    path: '/sell-list',
    name: 'sell-list',
    component: () => import('../views/ams/sell-list.vue')
  },
  {
    path: '/gift-list',
    name: 'gift-list',
    component: () => import('../views/ams/gift-list.vue')
  }
]
const router = createRouter({
  history: createWebHashHistory(),
  routes
})
export default router
